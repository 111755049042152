import React, { PureComponent } from 'react';

import './style.css';

class Footer extends PureComponent {
	render() {
		return (<div className="footer">
			2023 Julie Neumanová
		</div>);
	}
}

export default Footer;
