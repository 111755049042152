import React, { PureComponent } from 'react';

import TopBar from '../TopBar/TopBar';

import './style.css';

class Header extends PureComponent {
	render() {
		return (<header className="header">
			<TopBar />

			<div className="banner">
				<div className="banner-title">
					<h1 className="banner-title-big">
						Julie Neumanová
					</h1>
					<p className="banner-title-small">
						Full-Stack JavaScript Developer
					</p>
				</div>
			</div>
		</header>);
	}
}

export default Header;
